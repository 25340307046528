export const greetings: string[] = [
	"you're welcome.",
	"changing lives.",
	"welcome home.",
	"prepare for awesomeness.",
	"the highlight of your week.",
	"making smiles happen.",
	"recommended by handsome people everywhere.",
	"surprising you when you least expect it.",
	"a work of art.",
	"please do not touch",
];
